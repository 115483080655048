import { isNumber, ONE_MILLION, RATIO_UNIT } from '@netpurpose/types'
import { formatNumberWithThousandGroups } from '../formatting'
import { formatRatioAsPercent } from './formatRatioAsPercent'

const unitDecimalPlaces: { [key: string]: number } = {
  billion: 2,
  '$ (USD) million': 2,
}

const getDecimalPlaces = (value: number, unitName?: string) => {
  if (unitName !== undefined) {
    const decimal_places = unitDecimalPlaces[unitName]
    if (decimal_places !== undefined) {
      return decimal_places
    }
  }
  return value < 1 ? 5 : value >= ONE_MILLION ? 0 : 2
}

/**
 * Use this for all fact value formatting except for edge cases.
 *
 * @param value the value
 * @param unitName the unit name
 * @returns string
 */
export const formatValue = (
  value: string | number | undefined,
  unitName?: string,
  shouldFormatRatios = true,
) => {
  if (value === undefined) {
    return ''
  }

  if (!isNumber(value)) {
    return String(value)
  }

  if (unitName === RATIO_UNIT && shouldFormatRatios) {
    return formatRatioAsPercent(value)
  }

  return formatNumberWithThousandGroups(value, getDecimalPlaces(value, unitName))
}
