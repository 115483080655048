import { StateCreator } from 'zustand'
import { updateSelectedOptions } from '@netpurpose/utils'

export type CompanyDashboardFiltersSlice = {
  selectedCompanyDashboardSDGs: string[]
  updateSelectedCompanyDashboardSDGs: (sdg: string) => void
  setSelectedCompanyDashboardSDGs: (sdgs: string[]) => void
  clearSelectedCompanyDashboardSDGs: () => void
}

export const companyDashboardFiltersSlice: StateCreator<CompanyDashboardFiltersSlice> = (set) => ({
  selectedCompanyDashboardSDGs: [],
  updateSelectedCompanyDashboardSDGs: (sdg) =>
    set((state) => ({
      selectedCompanyDashboardSDGs: updateSelectedOptions(sdg, state.selectedCompanyDashboardSDGs),
    })),
  setSelectedCompanyDashboardSDGs: (sdgs) =>
    set(() => ({
      selectedCompanyDashboardSDGs: sdgs,
    })),
  clearSelectedCompanyDashboardSDGs: () =>
    set(() => ({
      selectedCompanyDashboardSDGs: [],
    })),
})
