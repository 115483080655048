import { StateCreator } from 'zustand'
import { ImpactLevel } from '@netpurpose/api'
import { updateSelectedOptions } from '@netpurpose/utils'

export type PortfolioDashboardFiltersSlice = {
  selectedThemes: string[]
  updateSelectedThemes: (theme: string) => void
  setSelectedThemes: (themes: string[]) => void
  clearSelectedThemes: () => void
  selectedImpactLevels: ImpactLevel[]
  updateSelectedImpactLevels: (impactLevel: ImpactLevel) => void
  setSelectedImpactLevels: (impactLevels: ImpactLevel[]) => void
  clearSelectedImpactLevels: () => void
  selectedSDGs: number[]
  updateSelectedSDGs: (sdgMajorCode: number) => void
  setSelectedSDGs: (sdgMajorCode: number[]) => void
  clearSelectedSDGs: () => void
  clearAllPortfolioDashboardFilters: () => void
}

export const portfolioDashboardFiltersSlice: StateCreator<PortfolioDashboardFiltersSlice> = (
  set,
) => ({
  selectedThemes: [],
  updateSelectedThemes: (theme) =>
    set((state) => ({
      selectedThemes: updateSelectedOptions(theme, state.selectedThemes),
    })),
  setSelectedThemes: (themes) =>
    set(() => ({
      selectedThemes: themes,
    })),
  clearSelectedThemes: () =>
    set(() => ({
      selectedThemes: [],
    })),
  selectedImpactLevels: [],
  updateSelectedImpactLevels: (impactLevel) =>
    set((state) => ({
      selectedImpactLevels: updateSelectedOptions(impactLevel, state.selectedImpactLevels),
    })),
  setSelectedImpactLevels: (impactLevels) =>
    set(() => ({
      selectedImpactLevels: impactLevels,
    })),
  clearSelectedImpactLevels: () =>
    set(() => ({
      selectedImpactLevels: [],
    })),
  selectedSDGs: [],
  updateSelectedSDGs: (sdg) =>
    set((state) => ({
      selectedSDGs: updateSelectedOptions(sdg, state.selectedSDGs),
    })),
  setSelectedSDGs: (sdgs) =>
    set(() => ({
      selectedSDGs: sdgs,
    })),
  clearSelectedSDGs: () =>
    set(() => ({
      selectedSDGs: [],
    })),
  clearAllPortfolioDashboardFilters: () =>
    set(() => ({
      selectedThemes: [],
      selectedImpactLevels: [],
      selectedSDGs: [],
    })),
})
