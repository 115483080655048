import { StateCreator } from 'zustand'
import { updateSelectedOptions } from '@netpurpose/utils'

export type CompanySDGsFiltersSlice = {
  selectedCompanySDGs: number[]
  updateSelectedCompanySDGs: (sdg: number) => void
  setSelectedCompanySDGs: (sdgs: number[]) => void
  clearSelectedCompanySDGs: () => void
}

export const companySDGsFiltersSlice: StateCreator<CompanySDGsFiltersSlice> = (set) => ({
  selectedCompanySDGs: [],
  updateSelectedCompanySDGs: (sdg) =>
    set((state) => ({
      selectedCompanySDGs: updateSelectedOptions(sdg, state.selectedCompanySDGs),
    })),
  setSelectedCompanySDGs: (sdgs) =>
    set(() => ({
      selectedCompanySDGs: sdgs,
    })),
  clearSelectedCompanySDGs: () =>
    set(() => ({
      selectedCompanySDGs: [],
    })),
})
