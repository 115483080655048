import { StateCreator } from 'zustand'
import { updateSelectedOptions } from '@netpurpose/utils'

export type SelectedEntitiesForWatchPortfolioSlice = {
  selectedEntities: number[]
  updateSelectedEntities: (id: number) => void
  addToSelectedEntities: (ids: number[]) => void
  clearSelectedEntities: () => void
}

export const selectedEntitiesForWatchPortfolioSlice: StateCreator<
  SelectedEntitiesForWatchPortfolioSlice
> = (set) => ({
  selectedEntities: [],
  updateSelectedEntities: (id) =>
    set((state) => ({
      selectedEntities: updateSelectedOptions(id, state.selectedEntities),
    })),
  addToSelectedEntities: (ids) =>
    set((state) => ({
      selectedEntities: [...state.selectedEntities, ...ids],
    })),
  clearSelectedEntities: () =>
    set(() => ({
      selectedEntities: [],
    })),
})
