import { StateCreator } from 'zustand'
import { ImpactLevel } from '@netpurpose/api'
import { DataType, QuestionSelectedStatus, Standard, StandardClass } from '@netpurpose/types'
import { updateSelectedOptions } from '@netpurpose/utils'

export type DataDictionaryFiltersSlice = {
  selectedQuestions: Map<number, boolean>
  setSelectedQuestions: (questionIdMap: Map<number, boolean>) => void
  selectedQuestionSelectedStatuses: QuestionSelectedStatus[]
  updateSelectedQuestionSelectedStatuses: (questionSelectedStatus: QuestionSelectedStatus) => void
  setSelectedQuestionSelectedStatuses: (questionSelectedStatus: QuestionSelectedStatus[]) => void
  clearSelectedQuestionSelectedStatuses: () => void
  selectedThemes: string[]
  updateSelectedThemes: (theme: string) => void
  setSelectedThemes: (themes: string[]) => void
  clearSelectedThemes: () => void
  selectedDataTypes: DataType[]
  updateSelectedDataTypes: (dataType: DataType) => void
  setSelectedDataTypes: (dataTypes: DataType[]) => void
  clearSelectedDataTypes: () => void
  selectedImpactLevels: ImpactLevel[]
  updateSelectedImpactLevels: (impactLevel: ImpactLevel) => void
  setSelectedImpactLevels: (impactLevels: ImpactLevel[]) => void
  clearSelectedImpactLevels: () => void
  selectedStandardFrameworks: StandardClass[]
  updateSelectedStandardFrameworks: (standardFramework: StandardClass) => void
  setSelectedStandardFrameworks: (standardFrameworks: StandardClass[]) => void
  clearSelectedStandardFrameworks: () => void
  selectedStandardTargets: Standard[]
  updateSelectedStandardTargets: (target: Standard) => void
  setSelectedStandardTargets: (targets: Standard[]) => void
  clearSelectedStandardTargets: () => void
  appliedStandardTargets: Standard[]
  applyStandardTargets: () => void
  setAppliedStandardTargets: (targets: Standard[]) => void
  clearAppliedStandardTargets: () => void
  removeAppliedStandardTarget: (id: Standard['id']) => void
  clearAllDataDictionaryFilters: () => void
}

export const dataDictionaryFiltersSlice: StateCreator<DataDictionaryFiltersSlice> = (set) => ({
  selectedQuestions: new Map<number, boolean>(),
  setSelectedQuestions: (questionIdMap) =>
    set(() => ({
      selectedQuestions: questionIdMap,
    })),
  selectedQuestionSelectedStatuses: [],
  updateSelectedQuestionSelectedStatuses: (questionSelectedStatus) =>
    set((state) => ({
      selectedQuestionSelectedStatuses: updateSelectedOptions(
        questionSelectedStatus,
        state.selectedQuestionSelectedStatuses,
      ),
    })),
  setSelectedQuestionSelectedStatuses: (questionSelectedStatuses) =>
    set(() => ({
      selectedQuestionSelectedStatuses: questionSelectedStatuses,
    })),
  clearSelectedQuestionSelectedStatuses: () =>
    set(() => ({
      selectedQuestionSelectedStatuses: [],
    })),
  selectedThemes: [],
  updateSelectedThemes: (theme) =>
    set((state) => ({
      selectedThemes: updateSelectedOptions(theme, state.selectedThemes),
    })),
  setSelectedThemes: (themes) =>
    set(() => ({
      selectedThemes: themes,
    })),
  clearSelectedThemes: () =>
    set(() => ({
      selectedThemes: [],
    })),
  selectedDataTypes: [],
  updateSelectedDataTypes: (dataType) =>
    set((state) => ({
      selectedDataTypes: updateSelectedOptions(dataType, state.selectedDataTypes),
    })),
  setSelectedDataTypes: (dataTypes) =>
    set(() => ({
      selectedDataTypes: dataTypes,
    })),
  clearSelectedDataTypes: () =>
    set(() => ({
      selectedDataTypes: [],
    })),
  selectedImpactLevels: [],
  updateSelectedImpactLevels: (impactLevel) =>
    set((state) => ({
      selectedImpactLevels: updateSelectedOptions(impactLevel, state.selectedImpactLevels),
    })),
  setSelectedImpactLevels: (impactLevels) =>
    set(() => ({
      selectedImpactLevels: impactLevels,
    })),
  clearSelectedImpactLevels: () =>
    set(() => ({
      selectedImpactLevels: [],
    })),
  selectedStandardFrameworks: [],
  updateSelectedStandardFrameworks: (standardFramework) =>
    set((state) => ({
      selectedStandardFrameworks: updateSelectedOptions(
        standardFramework,
        state.selectedStandardFrameworks,
      ),
    })),
  setSelectedStandardFrameworks: (standardFrameworks) =>
    set(() => ({
      selectedStandardFrameworks: standardFrameworks,
    })),
  clearSelectedStandardFrameworks: () =>
    set(() => ({
      selectedStandardFrameworks: [],
    })),
  selectedStandardTargets: [],
  updateSelectedStandardTargets: (target) =>
    set((state) => ({
      selectedStandardTargets: updateSelectedOptions(target, state.selectedStandardTargets),
    })),
  setSelectedStandardTargets: (targets) =>
    set(() => ({
      selectedStandardTargets: targets,
    })),
  clearSelectedStandardTargets: () =>
    set(() => ({
      selectedStandardTargets: [],
    })),
  appliedStandardTargets: [],
  applyStandardTargets: () =>
    set((state) => ({
      appliedStandardTargets: state.selectedStandardTargets,
    })),
  setAppliedStandardTargets: (targets) =>
    set(() => ({
      appliedStandardTargets: targets,
    })),
  clearAppliedStandardTargets: () =>
    set(() => ({
      appliedStandardTargets: [],
    })),
  removeAppliedStandardTarget: (id) => {
    set((state) => ({
      appliedStandardTargets: state.appliedStandardTargets.filter((target) => target.id !== id),
      selectedStandardTargets: state.selectedStandardTargets.filter((target) => target.id !== id),
    }))
  },
  clearAllDataDictionaryFilters: () =>
    set(() => ({
      selectedQuestionSelectedStatuses: [],
      selectedThemes: [],
      selectedDataTypes: [],
      selectedImpactLevels: [],
      selectedStandardFrameworks: [],
      selectedStandardTargets: [],
      appliedStandardTargets: [],
    })),
})
