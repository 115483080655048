type NamedQuestion = {
  name: string
  displayName?: string
}

export const formatMetricName = (metric: NamedQuestion) => {
  return metric.name
}
export const formatMetricDisplayName = (metric: NamedQuestion) => {
  if (metric.displayName) {
    return metric.displayName
  }
  return metric.name
}
