import { StateCreator } from 'zustand'
import { DatapointViewerProps } from '@netpurpose/types'

type EstimationModalView = 'estimationDetail' | 'datapointViewer'

export type EstimationModalSlice = {
  estimationModalView: EstimationModalView
  setEstimationModalView: (modalView: EstimationModalView) => void
  datapointMetadata: DatapointViewerProps | null
  setDatapointMetadata: (datapointMetadata: DatapointViewerProps | null) => void
  updateDatapointMetadata: (datapointMetadataToUpdate: DatapointViewerProps) => void
}

export const estimationModalSlice: StateCreator<EstimationModalSlice> = (set) => ({
  estimationModalView: 'estimationDetail',
  setEstimationModalView: (modalView) =>
    set(() => ({
      estimationModalView: modalView,
    })),
  datapointMetadata: null,
  setDatapointMetadata: (datapointMetadata) =>
    set(() => ({
      datapointMetadata,
    })),
  updateDatapointMetadata: (datapointMetadataToUpdate) =>
    set((state) => ({
      datapointMetadata: {
        ...state.datapointMetadata,
        ...datapointMetadataToUpdate,
      },
    })),
})
