import { isNumber, NOT_FOUND, ONE_BILLION, ONE_MILLION } from '@netpurpose/types'
import { config } from '../config'
import { formatNumberWithThousandGroups } from './formatNumberWithThousandGroups'

export const formatDollarsAsMillions = ({
  dollars,
  minimumFractionDigits = 1,
  maximumFractionDigits = 1,
  withMillionsSuffix = false,
  preserveSubBillionValues = false,
  preserveSubMillionValues = false,
  addSpaceAfterDollarSign = false,
}: {
  dollars: number | null | undefined
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  withMillionsSuffix?: boolean
  preserveSubBillionValues?: boolean
  preserveSubMillionValues?: boolean
  addSpaceAfterDollarSign?: boolean
}) => {
  if (!isNumber(dollars)) {
    return NOT_FOUND
  }

  if (preserveSubBillionValues && Math.abs(dollars) < ONE_BILLION) {
    return `$${addSpaceAfterDollarSign ? ' ' : ''}${formatNumberWithThousandGroups(dollars)}`
  }

  if (preserveSubMillionValues && Math.abs(dollars) < ONE_MILLION) {
    return `$${addSpaceAfterDollarSign ? ' ' : ''}${formatNumberWithThousandGroups(dollars)}`
  }

  const millions = (dollars / ONE_MILLION).toLocaleString(config.locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  })

  return withMillionsSuffix
    ? `$${addSpaceAfterDollarSign ? ' ' : ''}${millions}M`
    : `$${addSpaceAfterDollarSign ? ' ' : ''}${millions}`
}
