import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { UnitLegacy } from '@netpurpose/types'
import { apiConfig } from '../../config'
import { Unit as BackendUnit } from '../../generated/facts'
import { GeneratedFactApi } from '../../GeneratedApi'
import { ConvertUnitRequest, transformUnit, UnitApi } from '../../models'
import { Camelize, snakeToCamelKeys } from '../../utils'
import { useApi } from '../useApi'
import { getUseModelById, getUseModelListFromPaginatedApi, getUsePaginatedModel } from '../useModel'

export const usePaginatedUnits = getUsePaginatedModel<UnitLegacy, UnitApi>('units', 'unit')

export const useUnit = ({ unitId }: { unitId: number | undefined }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['unit', unitId],
    queryFn: () => (unitId ? GeneratedFactApi.units.getUnit({ instanceId: unitId }) : undefined),
    enabled: !!unitId,
  })
  // NOTE: transformUnit is used here to temporarily rename keys, we intend to remove it once code-gen migration is complete
  const transformedUnit = useMemo(
    () => (data ? transformUnit(snakeToCamelKeys(data)) : undefined),
    [data],
  )

  return {
    ...rest,
    data: transformedUnit,
  }
}

export const useUnitsByIdLegacy = getUseModelById<UnitLegacy, UnitApi>('unitsByIdLegacy', 'unit')

export const useUnitsById = ({
  ids,
  enabled = true,
  staleTime = apiConfig.defaultStaleTime,
}: {
  ids?: number[]
  enabled?: boolean
  staleTime?: number
}) => {
  const uniqueIds = useMemo(() => [...new Set(ids)].map((id) => `ids=${id}`), [ids])
  const limit = uniqueIds.length || 9999

  const { data, ...rest } = useQuery({
    queryKey: ['unitsById', ids],

    queryFn: () =>
      GeneratedFactApi.units.listUnits({
        q: `limit=${limit}${uniqueIds.length ? `&${uniqueIds.join('&')}` : ''}`,
      }),

    staleTime,
    enabled,
  })

  const unitsById = useMemo(() => {
    return data?.results?.reduce<Record<number, Camelize<BackendUnit>>>((acc, unit) => {
      if (typeof unit.unit_id === 'number') {
        return {
          [unit.unit_id]: snakeToCamelKeys(unit),
          ...acc,
        }
      }
      return acc
    }, {})
  }, [data?.results])

  return {
    ...rest,
    data: unitsById,
  }
}

export type Unit = NonNullable<ReturnType<typeof useUnitsById>['data']>[number]

export const useUnits = getUseModelListFromPaginatedApi<UnitLegacy, UnitApi>('unitsList', 'unit')

export const useConvertUnit = (params: ConvertUnitRequest | null) => {
  const { api } = useApi()
  return useQuery({
    queryKey: ['convertUnit', params],
    queryFn: () => (params ? api.unit.convertUnit(params) : null),
    enabled: !!params,
  })
}

type CreateUnitPayload = Parameters<
  (typeof GeneratedFactApi)['units']['createUnit']
>[0]['requestBody']

export const useCreateUnit = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: (inputs: CreateUnitPayload) =>
      GeneratedFactApi.units.createUnit({ requestBody: inputs }),
  })
  return {
    isFetching: isPending,
    actions: { createUnit: mutate },
  }
}

export const useUpdateUnit = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: (inputs: Parameters<(typeof GeneratedFactApi)['units']['updateUnit']>[number]) =>
      GeneratedFactApi.units.updateUnit(inputs),
  })
  return {
    isFetching: isPending,
    actions: { updateUnit: mutate },
  }
}
