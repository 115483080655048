import {
  keepPreviousData,
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { MetricPolymorph } from '@netpurpose/types'
import { apiConfig } from '../../config'
import { ApiError, Metric as GeneratedMetric } from '../../generated/facts'
import { GeneratedFactApi } from '../../GeneratedApi'
import { getPaginationConfig, useUrlApiConnector } from '../../hooks/useUrlTableApiConnector'
import { MetricApi, reverseMetricFieldMap } from '../../models'
import { Camelize, snakeToCamelKeys } from '../../utils'
import { useApi } from '../useApi'
import { getUseModel, getUseModelById, getUsePaginatedModel, getUseUpdateModel } from '../useModel'

const metricsQueryCacheKeys = {
  paginatedMetrics: 'metrics',
  metric: 'metric',
  allMetrics: 'allMetrics',
  metricsById: 'metricsById',
} as const

const invalidateMetricsQueries = (queryClient: QueryClient, metricId?: string): void => {
  if (metricId) {
    queryClient.invalidateQueries({
      queryKey: [metricsQueryCacheKeys.metric, metricId],
    })
  }
  queryClient.invalidateQueries({
    queryKey: [metricsQueryCacheKeys.paginatedMetrics],
  })
  queryClient.invalidateQueries({
    queryKey: [metricsQueryCacheKeys.allMetrics],
  })
  queryClient.invalidateQueries({
    queryKey: [metricsQueryCacheKeys.metricsById],
  })
}

export const usePaginatedMetricsLegacy = getUsePaginatedModel<MetricPolymorph, MetricApi>(
  metricsQueryCacheKeys.paginatedMetrics,
  'metric',
)

type Metric = Camelize<GeneratedMetric>

// TODO: this is currently not used and likely will need to have some properties transformed
// to replace existing usePaginatedMetricsLegacy
export const usePaginatedMetrics = ({
  includeSimple = true,
  defaultParams,
  staleTime = apiConfig.defaultStaleTime,
}: {
  includeSimple: boolean
  defaultParams?: { [key: string]: string | number | string[] }
  staleTime?: number
}) => {
  const queryCacheKeyRoot = 'listMetrics'

  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<Metric>({
    tableToApiFieldMap: reverseMetricFieldMap,
    urlKey: queryCacheKeyRoot,
    defaultParams,
  })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString],

    queryFn: () =>
      GeneratedFactApi.metrics.listMetrics({
        includeSimple,
        q: queryString,
      }),

    enabled: !!queryString,
    placeholderData: keepPreviousData,
    staleTime,
  })

  const transformedMetrics = useMemo(() => (data?.results || []).map(snakeToCamelKeys), [data])

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: transformedMetrics,
    filterConfig,
    paginationConfig,
  }
}

export const useMetricLegacy = getUseModel<MetricPolymorph, MetricApi>('metric', 'metric')

export const useCreateMetricLegacy = () => {
  const [newMetric, setNewMetric] = useState<MetricPolymorph | null>(null)
  const { api } = useApi()
  const queryClient = useQueryClient()

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (payload: Partial<MetricPolymorph>) => api.metric.create(payload),
    onSuccess: (metric) => {
      setNewMetric(metric)
      invalidateMetricsQueries(queryClient)
    },
  })
  return { mutate, metric: newMetric, isLoading: isPending, isSuccess }
}

export const useUpdateMetricLegacy = getUseUpdateModel<MetricPolymorph, MetricApi>('metric', [
  metricsQueryCacheKeys.paginatedMetrics,
  metricsQueryCacheKeys.allMetrics,
  metricsQueryCacheKeys.metricsById,
])

export const useDeleteMetric = ({ onError }: { onError: (error: ApiError) => void }) => {
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: ({ metricId }: { metricId: string }) =>
      GeneratedFactApi.metrics.deleteMetric({ metricId }),
    onSuccess: () => invalidateMetricsQueries(queryClient),
    // @ts-expect-error - err.body is unknown type
    onError: (err: ApiError) => onError(err.body?.detail),
  })
  return { mutate, isPending }
}

// TODO: revisit all use cases to update to useMetricsById where fetching by ids is preferred
export const useAllMetricsById = ({
  includeSimple,
  enabled = true,
  staleTime = apiConfig.defaultStaleTime,
}: {
  includeSimple: boolean
  enabled?: boolean
  staleTime?: number
}) => {
  const { api } = useApi()
  return useQuery({
    queryKey: [metricsQueryCacheKeys.allMetrics],
    queryFn: () => api.metric.fetchAllMetrics(includeSimple),
    enabled,
    staleTime,
  })
}

// When not to include simple metrics:
// Facts and IngestionTask pages need major metrics with extraction instructions
// https://www.notion.so/netpurpose/Metrics-KPIs-Questions-fbf0304a031d4652a3740b0bf2e2e7ba
export const useMetricsById = ({
  metricIds,
  enabled,
  includeSimpleMetrics = true,
  staleTime = apiConfig.defaultStaleTime,
}: {
  metricIds: string[]
  enabled: boolean
  includeSimpleMetrics?: boolean
  staleTime?: number
}) => {
  return getUseModelById<MetricPolymorph, MetricApi>(metricsQueryCacheKeys.metricsById, 'metric')(
    metricIds,
    { enabled, staleTime },
    { include_simple: includeSimpleMetrics },
  )
}
