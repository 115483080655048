import { NOT_FOUND } from '@netpurpose/types'
import { formatRatioAsPercent } from './formatRatioAsPercent'

export const formatFractionAsPercent = (numerator?: number, denominator?: number) => {
  if (numerator === undefined || denominator === undefined) {
    return NOT_FOUND
  }

  const fraction = numerator / denominator
  return formatRatioAsPercent(fraction)
}
