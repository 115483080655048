import { config } from '../config'
import { formatDollars } from './formatDollars'
import { formatDollarsAsMillions } from './formatDollarsAsMillions'
import { formatFractionAsPercent } from './formatFractionAsPercent'
import { formatMetricDisplayName, formatMetricName } from './formatMetricName'
import { formatNumber } from './formatNumber'
import { formatRatioAsPercent } from './formatRatioAsPercent'
import { formatScientificNumber } from './formatScientificNumber'
import { formatValue } from './formatValue'

const defaultFormatters = {
  datapointValue: formatValue,
  dollars: formatDollars,
  dollarsAsMillions: formatDollarsAsMillions,
  fractionAsPercent: formatFractionAsPercent,
  number: formatNumber,
  ratioAsPercent: formatRatioAsPercent,
  scientificNumber: formatScientificNumber,
  value: formatValue,
  metricName: formatMetricName,
}

export const controlFormatters = {
  ...defaultFormatters,
  datapointValue: formatScientificNumber,
  metricName: formatMetricName,
}

export const clientAppFormatters = {
  ...defaultFormatters,
  datapointValue: formatValue,
  metricName: formatMetricDisplayName,
}

export const selectFormatter = () => {
  switch (config.packageName) {
    case 'clientApp':
      return clientAppFormatters
    case 'control':
      return controlFormatters
    default:
      return defaultFormatters
  }
}
