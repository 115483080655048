import { isNumber, NOT_FOUND } from '@netpurpose/types'

const countDecimalPlaces = (value: number): number => {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1]?.length || 0
  }
  return 0
}

const toExponential = (value: number) => {
  const decimalPlacesToShow = 3

  const isExponential = value.toString().includes('e')
  if (isExponential) {
    return value.toExponential(decimalPlacesToShow)
  }

  const boundary = 10 ** decimalPlacesToShow

  const isValueWithinBoundary = value > -boundary && value < boundary
  if (isValueWithinBoundary && countDecimalPlaces(value) <= decimalPlacesToShow) {
    return String(value.toFixed(decimalPlacesToShow))
  }

  return value.toExponential(decimalPlacesToShow)
}

export const formatScientificNumber = (value?: number): string =>
  isNumber(value) ? toExponential(value) : NOT_FOUND
