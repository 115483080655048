import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Kpi, ONE_HOUR_MS } from '@netpurpose/types'
import { GeneratedFactApi } from '../../GeneratedApi'
import { KpiApi, kpiTableToApiFieldMap, transformKpi } from '../../models'
import { snakeToCamelKeys } from '../../utils'
import {
  getUseCreateModel,
  getUseDeleteModel,
  getUseModel,
  getUseModelById,
  getUseUpdateModel,
} from '../useModel'
import { getPaginationConfig, useUrlApiConnector } from '../useUrlTableApiConnector'

export const usePaginatedKpis = () => {
  const queryCacheKeyRoot = 'paginatedKpis'
  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<Kpi>({
    tableToApiFieldMap: kpiTableToApiFieldMap,
    urlKey: queryCacheKeyRoot,
  })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString],

    queryFn: () =>
      GeneratedFactApi.kpis.listKpis({
        q: queryString,
      }),

    enabled: !!queryString,
    staleTime: ONE_HOUR_MS,
    placeholderData: keepPreviousData,
  })
  const camelKpis = snakeToCamelKeys(data?.results)
  const transformedKpis = useMemo(() => (camelKpis ? camelKpis.map(transformKpi) : []), [camelKpis])

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      kpis: transformedKpis,
    },
    filterConfig,
    paginationConfig,
  }
}

export const useKpisById = getUseModelById<Kpi, KpiApi>('kpisById', 'kpi')

export const useKpi = getUseModel<Kpi, KpiApi>('kpi', 'kpi')

export const useCreateKpi = getUseCreateModel<Kpi, KpiApi>('kpi')
export const useUpdateKpi = getUseUpdateModel<Kpi, KpiApi>('kpi')
export const useDeleteKpi = getUseDeleteModel<Kpi, KpiApi>('kpi')
