import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  companyDashboardFiltersSlice,
  CompanyDashboardFiltersSlice,
} from './companyDashboardFiltersSlice'
import { companySDGsFiltersSlice, CompanySDGsFiltersSlice } from './companySDGsFiltersSlice'
import {
  DataDictionaryFiltersSlice,
  dataDictionaryFiltersSlice,
} from './dataDictionaryFiltersSlice'
import { EstimationModalSlice, estimationModalSlice } from './estimationModalSlice'
import {
  PortfolioDashboardFiltersSlice,
  portfolioDashboardFiltersSlice,
} from './portfolioDashboardFiltersSlice'
import {
  selectedEntitiesForWatchPortfolioSlice,
  SelectedEntitiesForWatchPortfolioSlice,
} from './selectedEntitiesForWatchPortfolioSlice'

type StoreState = SelectedEntitiesForWatchPortfolioSlice &
  DataDictionaryFiltersSlice &
  PortfolioDashboardFiltersSlice &
  EstimationModalSlice &
  CompanySDGsFiltersSlice &
  CompanyDashboardFiltersSlice

export const useStore = create<StoreState>()(
  devtools((...args) => ({
    ...selectedEntitiesForWatchPortfolioSlice(...args),
    ...dataDictionaryFiltersSlice(...args),
    ...portfolioDashboardFiltersSlice(...args),
    ...estimationModalSlice(...args),
    ...companySDGsFiltersSlice(...args),
    ...companyDashboardFiltersSlice(...args),
  })),
)
